export enum PerformanceDeltaSet {
	Unknown = "Unknown",
	Wtr1stEdition = "Wtr1stEdition",
	WtrUnlimitedEdition = "WtrUnlimitedEdition",
	Arc1stEdition = "Arc1stEdition",
	ArcUnlimitedEdition = "ArcUnlimitedEdition",
	Cru1stEdition = "Cru1stEdition",
	CruUnlimitedEdition = "CruUnlimitedEdition",
	Mon1stEdition = "Mon1stEdition",
	MonUnlimitedEdition = "MonUnlimitedEdition",
	Ele1stEdition = "Ele1stEdition",
	EleUnlimitedEdition = "EleUnlimitedEdition",
	Evr1stEdition = "Evr1stEdition",
	Upr = "Upr",
	Dyn = "Dyn",
	Out = "Out",
	Dtd = "Dtd",
	Evo = "Evo",
	Hvy = "Hvy",
	Mst = "Mst",
	Ros = "Ros",
	Hnt = "Hnt",
	Lgs = "Lgs",
	Her = "Her",
	Fab = "Fab",
	Jdg = "Jdg",
	Lss = "Lss"
}
