export enum PrepopulateSet {
	Unknown = "Unknown",
	Wtr1stEdition = "Wtr1stEdition",
	WtrUnlimitedEdition = "WtrUnlimitedEdition",
	Arc1stEdition = "Arc1stEdition",
	ArcUnlimitedEdition = "ArcUnlimitedEdition",
	Cru1stEdition = "Cru1stEdition",
	CruUnlimitedEdition = "CruUnlimitedEdition",
	Mon1stEdition = "Mon1stEdition",
	MonUnlimitedEdition = "MonUnlimitedEdition",
	Ele1stEdition = "Ele1stEdition",
	EleUnlimitedEdition = "EleUnlimitedEdition",
	Evr1stEdition = "Evr1stEdition",
	Upr = "Upr",
	Dyn = "Dyn",
	Out = "Out",
	Dtd = "Dtd",
	Evo = "Evo",
	Lgs = "Lgs",
	Her = "Her",
	Fab = "Fab",
	Jdg = "Jdg",
	Lss = "Lss",
	Hvy = "Hvy",
	Mst = "Mst",
	Ros = "Ros",
	Hnt = "Hnt"
}

export const doesPrepopulateSetHaveColdFoils = (set: PrepopulateSet) => {
	return set !== PrepopulateSet.WtrUnlimitedEdition
		&& set !== PrepopulateSet.ArcUnlimitedEdition
		&& set !== PrepopulateSet.CruUnlimitedEdition
		&& set !== PrepopulateSet.MonUnlimitedEdition
		&& set !== PrepopulateSet.EleUnlimitedEdition;
};

export const doesPrepopulateSetHaveSuperRares = (set: PrepopulateSet) => {
	return set === PrepopulateSet.Wtr1stEdition
		|| set === PrepopulateSet.WtrUnlimitedEdition
		|| set === PrepopulateSet.Arc1stEdition
		|| set === PrepopulateSet.ArcUnlimitedEdition;
};

export const doesPrepopulateSetHaveMarvels = (set: PrepopulateSet) => {
	return set !== PrepopulateSet.Wtr1stEdition
		&& set !== PrepopulateSet.WtrUnlimitedEdition
		&& set !== PrepopulateSet.Arc1stEdition
		&& set !== PrepopulateSet.ArcUnlimitedEdition
		&& set !== PrepopulateSet.Cru1stEdition
		&& set !== PrepopulateSet.CruUnlimitedEdition
		&& set !== PrepopulateSet.Mon1stEdition
		&& set !== PrepopulateSet.MonUnlimitedEdition
		&& set !== PrepopulateSet.Ele1stEdition
		&& set !== PrepopulateSet.EleUnlimitedEdition
		&& set !== PrepopulateSet.Evr1stEdition
		&& set !== PrepopulateSet.Fab
		&& set !== PrepopulateSet.Her
		&& set !== PrepopulateSet.Jdg
		&& set !== PrepopulateSet.Lss
		&& set !== PrepopulateSet.Lgs;
};

export const isPromoSet = (set: PrepopulateSet) => {
	return set === PrepopulateSet.Lss
		|| set === PrepopulateSet.Lgs
		|| set === PrepopulateSet.Fab
		|| set === PrepopulateSet.Her
		|| set === PrepopulateSet.Jdg;
};
